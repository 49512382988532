<script setup>
import { ref, onMounted } from 'vue';
import { Clock, Timer, TimerIcon } from 'lucide-vue-next';

const props = defineProps({
    targetDate: {
        type: String,
        required: true
    }
});

const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);

const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const target = new Date(props.targetDate).getTime();
    const difference = target - now;

    if (difference > 0) {
        days.value = Math.floor(difference / (1000 * 60 * 60 * 24));
        hours.value = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        minutes.value = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        seconds.value = Math.floor((difference % (1000 * 60)) / 1000);
    }
};

onMounted(() => {
    calculateTimeLeft();
    setInterval(calculateTimeLeft, 1000);
});
</script>

<template>
    <div class="flex gap-8">
        <div class="bg-perlwhite/10 backdrop-blur-sm rounded-lg p-4 text-center">
            <Clock class="w-6 h-6 text-orange mx-auto mb-2" />
            <div class="text-2xl font-bold text-perlwhite">{{ days }}</div>
            <div class="text-sm text-silver-300">Tage</div>
        </div>

        <div class="bg-perlwhite/10 backdrop-blur-sm rounded-lg p-4 text-center">
            <Timer class="w-6 h-6 text-orange mx-auto mb-2" />
            <div class="text-2xl font-bold text-perlwhite">{{ hours }}</div>
            <div class="text-sm text-silver-300">Std</div>
        </div>

        <div class="bg-perlwhite/10 backdrop-blur-sm rounded-lg p-4 text-center">
            <Timer class="w-6 h-6 text-orange mx-auto mb-2" />
            <div class="text-2xl font-bold text-perlwhite">{{ minutes }}</div>
            <div class="text-sm text-silver-300">Min</div>
        </div>

        <div class="bg-perlwhite/10 backdrop-blur-sm rounded-lg p-4 text-center">
            <TimerIcon class="w-6 h-6 text-orange mx-auto mb-2" />
            <div class="text-2xl font-bold text-perlwhite">{{ seconds }}</div>
            <div class="text-sm text-silver-300">Sek</div>
        </div>
    </div>
</template>